const DESK_PHONE = 'DESK_PHONE';
const MAIN_LINE = 'MAIN_LINE';
const START_WORK = 'START_WORK';
const FULL_TIME = 'FULL_TIME';
const PART_TIME = 'PART_TIME';
const SERVE_DURATION_GLIP = 'SERVE_DURATION_GLIP';
const END_WORK = 'END_WORK';

export default {
  DESK_PHONE,
  MAIN_LINE,
  START_WORK,
  FULL_TIME,
  PART_TIME,
  SERVE_DURATION_GLIP,
  END_WORK
};
